<template>
    <el-select
        v-model="v"
        :placeholder="item.placeholder"
        :multiple="item.multi || false"
        :filterable="!!item.remote"
        :remote="!!item.remote"
        :remote-method="remote"
        reserve-keyword
    >
        <el-option
            v-for="op in options"
            :key="op[valueKey]"
            :value="item.all ? op : op[valueKey]"
            :label="op[labelKey]"
        ></el-option>
    </el-select>
</template>

<script>
export default {
    data() {
        return {
            options: this.item.options || []
        };
    },
    props: {
        item: {
            type: Object,
            default: null
        },
        data: {
            type: Object,
            default: null
        }
    },
    computed: {
        labelKey() {
            return this.item.labelKey || "label";
        },
        valueKey() {
            return this.item.valueKey || "value";
        },
        v: {
            get() {
                if (
                    this.item.all &&
                    this.data[this.item.key] &&
                    Object.prototype.toString.call(this.data[this.item.key]) !==
                        "[object Object]"
                ) {
                    let temp = null;
                    this.options.some(opt => {
                        if (opt[this.value] === this.val) {
                            temp = opt;
                            return true;
                        }
                    });
                    // this.data[this.item.key] = temp;
                    this.$set(this.data, this.item.key, temp);
                    return temp;
                }
                return this.data[this.item.key];
            },
            set(e) {
                // this.data[this.item.key] = e;
                this.$set(this.data, this.item.key, e);
            }
        }
    },
    mounted() {
        if (this.item.remote) {
            this.remote();
        }
    },
    methods: {
        remote(query) {
            this.item.remote(query).then(data => {
                this.options = data;
            });
        }
    }
};
</script>

<style lang="scss">
.el-select {
    width: 100%;
}
</style>